/* eslint-disable consistent-return */
import { UserRole } from 'constants/defaultValues';
import axios from 'axios';
import { setCurrentUser } from 'helpers/Utils';

export const allowedIP = ['201.17.113.45'];
// export const apiUrl = 'http://localhost:3010';
export const apiUrl = 'https://api.bluecredit.com.br';

export function getBankName(bankCode) {
  bankCode = parseFloat(bankCode);
  return mainBanks.filter((b) => b.code === bankCode)[0]
    ? mainBanks.filter((b) => b.code === bankCode)[0].name
    : 'Banco não encontrado';
}

export function getPermission(email) {
  // Tech
  if (email === 'alan@bluecredit.com.br') return UserRole.SuperAdmin; // Alan
  if (email === 'tech@bluecredit.com.br') return UserRole.SuperAdmin; // Ricardo
  if (email === 'ricardohcastro@hotmail.com') return UserRole.SuperAdmin; // Ricardo

  // Analyst
  if (email === 'contato@bluecredit.com.br') return UserRole.Admin; // Rai
  if (email === 'ana@bluecredit.com.br') return UserRole.Admin; // Rai
  if (email === 'atendimento@bluecredit.com.br') return UserRole.Analyst; // throw new Error("Not allowed"); // return UserRole.Analyst; // Rayani

  if (email === 'calmeida@bluecredit.com.br') return UserRole.Analyst; // Carol
  if (email === 'clara@bluecredit.com.br') return UserRole.Analyst; // Clara
  if (email === 'suporte@bluecredit.com.br') return UserRole.Analyst; // Dani
  if (email === 'cintia@bluecredit.com.br') return UserRole.Analyst; // Dani
  if (email === 'claudia@bluecredit.com.br') return UserRole.Analyst; // ...

  // Marketing
  if (email === 'mariana@bluecredit.com.br') return UserRole.SuperAdmin; // Mariana

  // Financial
  if (email === 'financeiro@bluecredit.com.br') return UserRole.SuperAdmin; // André
  if (email === 'gustavo@bluecredit.com.br') return UserRole.SuperAdmin; // Gustavo

  if (email === 'lucas@bluecredit.com.br') return UserRole.SuperAdmin; // Lucas Bulus
  if (email === 'henrique@bluecredit.com.br') return UserRole.SuperAdmin; // Lucas Bulus

  return UserRole.Partner;
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getTitle(email) {
  // Tech
  if (email === 'tech2@Bluecredit.com.br') return 'Lucas Cavalcante'; // Lucas Cavalcante
  if (email === 'tech@Bluecredit.com.br') return 'Ricardo'; // Ricardo

  // Analyst
  if (email === 'contato@Bluecredit.com.br') return 'Camila'; // Fernanda
  if (email === 'atendimento@Bluecredit.com.br') return 'Chris'; // Rayani

  if (email === 'calmeida@Bluecredit.com.br') return 'Carol'; // Carol
  if (email === 'clara@Bluecredit.com.br') return 'Clara'; // Clara
  if (email === 'suporte@Bluecredit.com.br') return 'Cleo'; // Dani

  // Marketing
  if (email === 'mariana@Bluecredit.com.br') return 'Mariana'; // Mariana

  // Financial
  if (email === 'financeiro@Bluecredit.com.br') return 'André'; // André
  if (email === 'gustavo@Bluecredit.com.br') return 'Gustavo'; // Gustavo

  if (email === 'lucas@Bluecredit.com.br') return 'Lucas Bulus'; // Lucas Bulus

  if (email === 'claudia@Bluecredit.com.br') return 'Claudia'; // ...

  return capitalizeFirstLetter(email.split('@')[0]);
}

export async function getCompany(email) {
  const companyResponse = await axios.post(`${apiUrl}/getUserInformation`, {
    email,
  });
  return companyResponse.data;
}

export function getBaseCompany(email) {
  if (
    [
      'Jheniffer.alves@gcmconsig.com.br',
      'raissa.pionte@grupoglm.com.br',
      'anapcristian@gmail.com',
      'viniciusaugustoc@gmail.com',
      'annalavinscky@gmail.com',
      'filipe.piani@gmail.com',
      'micheleborgesteixeira@gmail.com',
      'gabriela.marinho@grupoglm.com.br',
      'Liviaalrossi@gmail.com',
      'analuizafranca00@gmail.com',
      'silvalimaaline29@gmail.com',
      'j.v.dupim95@gmail.com',
      'prime_intermediacoesfinanceiras@hotmail.com',
      'matheus_jjollo@hotmail.com',
      'leticia_sts@hotmail.com',
      'sofjjollo@gmail.com',
      'emylaineconsultoria@gmail.com',
      'EMYLAINECONSULTORIA@GMAIL.COM',
      'laismartinsaraujo81@gmail.com',
      'ssanevavianna@gmail.com',
      'tfernandavieira@gmail.com',
      'cssolucoesfinanceiras@gmail.com',
      'anapaulaalves1977@gmail.com',
      'cidinha0505@hotmail.com',
      'tfernandavieira@gmail.com',
      'cssolucoesfinanceiras@gmail.com',
    ].includes(email)
  ) {
    return 'GLM';
  }

  return email.split('@')[1].split('.')[0];
}

export function getFilter(userRole) {
  if (userRole === UserRole.Financial) {
    return {
      __v: 0,
      _id: 0,
      hasContactSigned: 0,
      hasTermSigned: 0,
      paymentHistory: 0,
      sendMessage: 0,
      leadHistory: 0,
      sentToForegon: 0,
      logs: 0,
      fine: 0,
      installmentDetails: 0,
      driveUrl: 0,
      lastPage: 0,
      userMother: 0,
      documentKey: 0,
      documentLink: 0,
      addressNumber: 0,
      procobData: 0,
      userRG: 0,
      extraInfo: 0,
      geoError: 0,
      ipInformation: 0,
      rawOldRes: 0,
      resOld2: 0,
      rawResponse: 0,
      contractData: 0,
      addressStreet: 0,
      bankAccount: 0,
      bankAccountDigit: 0,
      bankAgency: 0,
      bankAgencyDigit: 0,
      cardName: 0,
      cardNumberLast4: 0,
      cardValidity: 0,
      addressComplement: 0,
      cardCvv: 0,
      deviceInfo: 0,
      loanDetails: 0,
      analysisData: 0,
      document: 0,
    };
  }

  if (userRole === UserRole.Marketing) {
    return {
      hasContactSigned: 0,
      hasTermSigned: 0,
      paymentHistory: 0,
      sendMessage: 0,
      leadHistory: 0,
      __v: 0,
      sentToForegon: 0,
      _id: 0,
      fine: 0,
      installmentDetails: 0,
      driveUrl: 0,
      deviceInfo: 0,
      analysisData: 0,
      lastPage: 0,
      logs: 0,
      documentKey: 0,
      documentLink: 0,
      procobData: 0,
      userRG: 0,
      extraInfo: 0,
      geoError: 0,
      ipInformation: 0,
      userMother: 0,
      addressNumber: 0,
      acquirer: 0,
      finName: 0,
      rawOldRes: 0,
      resOld2: 0,
      rawResponse: 0,
      contractData: 0,
      operatorAssigned: 0,
      monthlyCet: 0,
      annuallyCet: 0,
      firstInstallmentDate: 0,
      lastInstallmentDate: 0,
      loanDetails: 0,
      userCPF: 0,
      addressStreet: 0,
      bankAccount: 0,
      bankAccountDigit: 0,
      bankAgency: 0,
      bankAgencyDigit: 0,
      cardName: 0,
      cardNumberLast4: 0,
      cardValidity: 0,
      ccbNumber: 0,
      nominalAmount: 0,
      addressComplement: 0,
      cardCvv: 0,
      accountCpf: 0,
      document: 0,
    };
  }

  return {
    hasContactSigned: 0,
    hasTermSigned: 0,
    paymentHistory: 0,
    sendMessage: 0,
    leadHistory: 0,
    __v: 0,
    sentToForegon: 0,
    _id: 0,
    fine: 0,
    installmentDetails: 0,
    driveUrl: 0,
    deviceInfo: 0,
    analysisData: 0,
    lastPage: 0,
    logs: 0,
    documentKey: 0,
    documentLink: 0,
    procobData: 0,
    userRG: 0,
    extraInfo: 0,
    geoError: 0,
    ipInformation: 0,
    rawOldRes: 0,
    resOld2: 0,
    rawResponse: 0,
    contractData: 0,
    operatorAssigned: 0,
    lastInstallmentDate: 0,
    loanDetails: 0,
    addressStreet: 0,
    cardValidity: 0,
    nominalAmount: 0,
    addressComplement: 0,
    cardCvv: 0,
    accountCpf: 0,
    document: 0,
  };
}

export const mainBanks = [
  {
    code: 1,
    name: 'Banco Do Brasil S.A (Bb)',
    concatenated: '1 - Banco Do Brasil S.A (Bb)',
  },
  {
    code: 237,
    name: 'Bradesco S.A',
    concatenated: '237 - Bradesco S.A',
  },
  {
    code: 341,
    name: 'Itaú Unibanco S.A',
    concatenated: '341 - Itaú Unibanco S.A',
  },
  {
    code: 104,
    name: 'Caixa Econômica Federal (Cef)',
    concatenated: '104 - Caixa Econômica Federal (Cef)',
  },
  {
    code: 33,
    name: 'Banco Santander Brasil S.A',
    concatenated: '33 - Banco Santander Brasil S.A',
  },
  {
    code: 41,
    name: 'Banrisul – Banco Do Estado Do Rio Grande Do Sul S.A',
    concatenated: '41 - Banrisul – Banco Do Estado Do Rio Grande Do Sul S.A',
  },
  {
    code: 335,
    name: 'Banco Digio S.A',
    concatenated: '335 - Banco Digio S.A',
  },
  {
    code: 102,
    name: 'Xp Investimentos S.A',
    concatenated: '102 - Xp Investimentos S.A',
  },
  {
    code: 260,
    name: 'Nu Pagamentos S.A (Nubank)',
    concatenated: '260 - Nu Pagamentos S.A (Nubank)',
  },
  {
    code: 290,
    name: 'Pagseguro Internet S.A',
    concatenated: '290 - Pagseguro Internet S.A',
  },
  {
    code: 323,
    name: 'Mercado Pago – Conta Do Mercado Livre',
    concatenated: '323 - Mercado Pago – Conta Do Mercado Livre',
  },
  {
    code: 237,
    name: 'Next Bank (Mesmo Código Do Bradesco)',
    concatenated: '237 - Next Bank (Mesmo Código Do Bradesco)',
  },
  {
    code: 637,
    name: 'Banco Sofisa S.A (Sofisa Direto)',
    concatenated: '637 - Banco Sofisa S.A (Sofisa Direto)',
  },
  {
    code: 77,
    name: 'Banco Inter S.A',
    concatenated: '77 - Banco Inter S.A',
  },
  {
    code: 212,
    name: 'Banco Original S.A',
    concatenated: '212 - Banco Original S.A',
  },
  {
    code: 756,
    name: 'Bancoob (Banco Cooperativo Do Brasil)',
    concatenated: '756 - Bancoob (Banco Cooperativo Do Brasil)',
  },
  {
    code: 655,
    name: 'Banco Votorantim S.A',
    concatenated: '655 - Banco Votorantim S.A',
  },
  {
    code: 655,
    name: 'Neon Pagamentos S.A (Memso Código Do Banco Votorantim)',
    concatenated:
      '655 - Neon Pagamentos S.A (Memso Código Do Banco Votorantim)',
  },
  {
    code: 389,
    name: 'Banco Mercantil Do Brasil S.A',
    concatenated: '389 - Banco Mercantil Do Brasil S.A',
  },
  {
    code: 422,
    name: 'Banco Safra S.A',
    concatenated: '422 - Banco Safra S.A',
  },
  {
    code: 70,
    name: 'Banco De Brasília (Brb)',
    concatenated: '70 - Banco De Brasília (Brb)',
  },
  {
    code: 136,
    name: 'Unicred Cooperativa',
    concatenated: '136 - Unicred Cooperativa',
  },
  {
    code: 348,
    name: 'Banco XP S.A.',
    concatenated: '348 - Banco XP S.A.',
  },
  {
    code: 741,
    name: 'Banco Ribeirão Preto',
    concatenated: '741 - Banco Ribeirão Preto',
  },
  {
    code: 739,
    name: 'Banco Cetelem S.A',
    concatenated: '739 - Banco Cetelem S.A',
  },
  {
    code: 743,
    name: 'Banco Semear S.A',
    concatenated: '743 - Banco Semear S.A',
  },
  {
    code: 100,
    name: 'Planner Corretora De Valores S.A',
    concatenated: '100 - Planner Corretora De Valores S.A',
  },
  {
    code: 96,
    name: 'Banco B3 S.A',
    concatenated: '96 - Banco B3 S.A',
  },
  {
    code: 747,
    name: 'Banco Rabobank Internacional Do Brasil S.A',
    concatenated: '747 - Banco Rabobank Internacional Do Brasil S.A',
  },
  {
    code: 748,
    name: 'Sicredi S.A',
    concatenated: '748 - Sicredi S.A',
  },
  {
    code: 752,
    name: 'Bnp Paribas Brasil S.A',
    concatenated: '752 - Bnp Paribas Brasil S.A',
  },
  {
    code: 91,
    name: 'Unicred Central Rs',
    concatenated: '91 - Unicred Central Rs',
  },
  {
    code: 399,
    name: 'Kirton Bank',
    concatenated: '399 - Kirton Bank',
  },
  {
    code: 108,
    name: 'Portocred S.A',
    concatenated: '108 - Portocred S.A',
  },
  {
    code: 757,
    name: 'Banco Keb Hana Do Brasil S.A',
    concatenated: '757 - Banco Keb Hana Do Brasil S.A',
  },
  {
    code: 348,
    name: 'Banco Xp S/A',
    concatenated: '348 - Banco Xp S/A',
  },
  {
    code: 340,
    name: 'Super Pagamentos S/A (Superdital)',
    concatenated: '340 - Super Pagamentos S/A (Superdital)',
  },
  {
    code: 84,
    name: 'Uniprime Norte Do Paraná',
    concatenated: '84 - Uniprime Norte Do Paraná',
  },
  {
    code: 180,
    name: 'Cm Capital Markets Cctvm Ltda',
    concatenated: '180 - Cm Capital Markets Cctvm Ltda',
  },
  {
    code: 66,
    name: 'Banco Morgan Stanley S.A',
    concatenated: '66 - Banco Morgan Stanley S.A',
  },
  {
    code: 15,
    name: 'Ubs Brasil Cctvm S.A',
    concatenated: '15 - Ubs Brasil Cctvm S.A',
  },
  {
    code: 143,
    name: 'Treviso Cc S.A',
    concatenated: '143 - Treviso Cc S.A',
  },
  {
    code: 62,
    name: 'Hipercard Bm S.A',
    concatenated: '62 - Hipercard Bm S.A',
  },
  {
    code: 74,
    name: 'Bco. J.Safra S.A',
    concatenated: '74 - Bco. J.Safra S.A',
  },
  {
    code: 99,
    name: 'Uniprime Central Ccc Ltda',
    concatenated: '99 - Uniprime Central Ccc Ltda',
  },
  {
    code: 25,
    name: 'Banco Alfa S.A.',
    concatenated: '25 - Banco Alfa S.A.',
  },
  {
    code: 75,
    name: 'Bco Abn Amro S.A',
    concatenated: '75 - Bco Abn Amro S.A',
  },
  {
    code: 40,
    name: 'Banco Cargill S.A',
    concatenated: '40 - Banco Cargill S.A',
  },
  {
    code: 190,
    name: 'Servicoop',
    concatenated: '190 - Servicoop',
  },
  {
    code: 63,
    name: 'Banco Bradescard',
    concatenated: '63 - Banco Bradescard',
  },
  {
    code: 191,
    name: 'Nova Futura Ctvm Ltda',
    concatenated: '191 - Nova Futura Ctvm Ltda',
  },
  {
    code: 64,
    name: 'Goldman Sachs Do Brasil Bm S.A',
    concatenated: '64 - Goldman Sachs Do Brasil Bm S.A',
  },
  {
    code: 97,
    name: 'Ccc Noroeste Brasileiro Ltda',
    concatenated: '97 - Ccc Noroeste Brasileiro Ltda',
  },
  {
    code: 16,
    name: 'Ccm Desp Trâns Sc E Rs',
    concatenated: '16 - Ccm Desp Trâns Sc E Rs',
  },
  {
    code: 12,
    name: 'Banco Inbursa',
    concatenated: '12 - Banco Inbursa',
  },
  {
    code: 3,
    name: 'Banco Da Amazonia S.A',
    concatenated: '3 - Banco Da Amazonia S.A',
  },
  {
    code: 60,
    name: 'Confidence Cc S.A',
    concatenated: '60 - Confidence Cc S.A',
  },
  {
    code: 37,
    name: 'Banco Do Estado Do Pará S.A',
    concatenated: '37 - Banco Do Estado Do Pará S.A',
  },
  {
    code: 159,
    name: 'Casa Credito S.A',
    concatenated: '159 - Casa Credito S.A',
  },
  {
    code: 172,
    name: 'Albatross Ccv S.A',
    concatenated: '172 - Albatross Ccv S.A',
  },
  {
    code: 85,
    name: 'Coop Central Ailos',
    concatenated: '85 - Coop Central Ailos',
  },
  {
    code: 114,
    name: 'Central Cooperativa De Crédito No Estado Do Espírito Santo',
    concatenated:
      '114 - Central Cooperativa De Crédito No Estado Do Espírito Santo',
  },
  {
    code: 36,
    name: 'Banco Bbi S.A',
    concatenated: '36 - Banco Bbi S.A',
  },
  {
    code: 394,
    name: 'Banco Bradesco Financiamentos S.A',
    concatenated: '394 - Banco Bradesco Financiamentos S.A',
  },
  {
    code: 4,
    name: 'Banco Do Nordeste Do Brasil S.A.',
    concatenated: '4 - Banco Do Nordeste Do Brasil S.A.',
  },
  {
    code: 320,
    name: 'Banco Ccb Brasil S.A',
    concatenated: '320 - Banco Ccb Brasil S.A',
  },
  {
    code: 189,
    name: 'Hs Financeira',
    concatenated: '189 - Hs Financeira',
  },
  {
    code: 105,
    name: 'Lecca Cfi S.A',
    concatenated: '105 - Lecca Cfi S.A',
  },
  {
    code: 76,
    name: 'Banco Kdb Brasil S.A.',
    concatenated: '76 - Banco Kdb Brasil S.A.',
  },
  {
    code: 82,
    name: 'Banco Topázio S.A',
    concatenated: '82 - Banco Topázio S.A',
  },
  {
    code: 286,
    name: 'Ccr De Ouro',
    concatenated: '286 - Ccr De Ouro',
  },
  {
    code: 93,
    name: 'Pólocred Scmepp Ltda',
    concatenated: '93 - Pólocred Scmepp Ltda',
  },
  {
    code: 273,
    name: 'Ccr De São Miguel Do Oeste',
    concatenated: '273 - Ccr De São Miguel Do Oeste',
  },
  {
    code: 157,
    name: 'Icap Do Brasil Ctvm Ltda',
    concatenated: '157 - Icap Do Brasil Ctvm Ltda',
  },
  {
    code: 183,
    name: 'Socred S.A',
    concatenated: '183 - Socred S.A',
  },
  {
    code: 14,
    name: 'Natixis Brasil S.A',
    concatenated: '14 - Natixis Brasil S.A',
  },
  {
    code: 130,
    name: 'Caruana Scfi',
    concatenated: '130 - Caruana Scfi',
  },
  {
    code: 127,
    name: 'Codepe Cvc S.A',
    concatenated: '127 - Codepe Cvc S.A',
  },
  {
    code: 79,
    name: 'Banco Original Do Agronegócio S.A',
    concatenated: '79 - Banco Original Do Agronegócio S.A',
  },
  {
    code: 81,
    name: 'Bbn Banco Brasileiro De Negocios S.A',
    concatenated: '81 - Bbn Banco Brasileiro De Negocios S.A',
  },
  {
    code: 118,
    name: 'Standard Chartered Bi S.A',
    concatenated: '118 - Standard Chartered Bi S.A',
  },
  {
    code: 133,
    name: 'Cresol Confederação',
    concatenated: '133 - Cresol Confederação',
  },
  {
    code: 121,
    name: 'Banco Agibank S.A',
    concatenated: '121 - Banco Agibank S.A',
  },
  {
    code: 83,
    name: 'Banco Da China Brasil S.A',
    concatenated: '83 - Banco Da China Brasil S.A',
  },
  {
    code: 138,
    name: 'Get Money Cc Ltda',
    concatenated: '138 - Get Money Cc Ltda',
  },
  {
    code: 24,
    name: 'Bco Bandepe S.A',
    concatenated: '24 - Bco Bandepe S.A',
  },
  {
    code: 95,
    name: 'Banco Confidence De Câmbio S.A',
    concatenated: '95 - Banco Confidence De Câmbio S.A',
  },
  {
    code: 94,
    name: 'Banco Finaxis',
    concatenated: '94 - Banco Finaxis',
  },
  {
    code: 276,
    name: 'Senff S.A',
    concatenated: '276 - Senff S.A',
  },
  {
    code: 137,
    name: 'Multimoney Cc Ltda',
    concatenated: '137 - Multimoney Cc Ltda',
  },
  {
    code: 92,
    name: 'Brk S.A',
    concatenated: '92 - Brk S.A',
  },
  {
    code: 47,
    name: 'Banco Bco Do Estado De Sergipe S.A',
    concatenated: '47 - Banco Bco Do Estado De Sergipe S.A',
  },
  {
    code: 144,
    name: 'Bexs Banco De Cambio S.A.',
    concatenated: '144 - Bexs Banco De Cambio S.A.',
  },
  {
    code: 126,
    name: 'Br Partners Bi',
    concatenated: '126 - Br Partners Bi',
  },
  {
    code: 301,
    name: 'Bpp Instituição De Pagamentos S.A',
    concatenated: '301 - Bpp Instituição De Pagamentos S.A',
  },
  {
    code: 173,
    name: 'Brl Trust Dtvm Sa',
    concatenated: '173 - Brl Trust Dtvm Sa',
  },
  {
    code: 119,
    name: 'Banco Western Union',
    concatenated: '119 - Banco Western Union',
  },
  {
    code: 254,
    name: 'Parana Banco S.A',
    concatenated: '254 - Parana Banco S.A',
  },
  {
    code: 268,
    name: 'Barigui Ch',
    concatenated: '268 - Barigui Ch',
  },
  {
    code: 107,
    name: 'Banco Bocom Bbm S.A',
    concatenated: '107 - Banco Bocom Bbm S.A',
  },
  {
    code: 412,
    name: 'Banco Capital S.A',
    concatenated: '412 - Banco Capital S.A',
  },
  {
    code: 124,
    name: 'Banco Woori Bank Do Brasil S.A',
    concatenated: '124 - Banco Woori Bank Do Brasil S.A',
  },
  {
    code: 149,
    name: 'Facta S.A. Cfi',
    concatenated: '149 - Facta S.A. Cfi',
  },
  {
    code: 197,
    name: 'Stone Pagamentos S.A',
    concatenated: '197 - Stone Pagamentos S.A',
  },
  {
    code: 142,
    name: 'Broker Brasil Cc Ltda',
    concatenated: '142 - Broker Brasil Cc Ltda',
  },
  {
    code: 389,
    name: 'Banco Mercantil Do Brasil S.A.',
    concatenated: '389 - Banco Mercantil Do Brasil S.A.',
  },
  {
    code: 184,
    name: 'Banco Itaú Bba S.A',
    concatenated: '184 - Banco Itaú Bba S.A',
  },
  {
    code: 634,
    name: 'Banco Triangulo S.A (Banco Triângulo)',
    concatenated: '634 - Banco Triangulo S.A (Banco Triângulo)',
  },
  {
    code: 545,
    name: 'Senso Ccvm S.A',
    concatenated: '545 - Senso Ccvm S.A',
  },
  {
    code: 132,
    name: 'Icbc Do Brasil Bm S.A',
    concatenated: '132 - Icbc Do Brasil Bm S.A',
  },
  {
    code: 298,
    name: 'Vips Cc Ltda',
    concatenated: '298 - Vips Cc Ltda',
  },
  {
    code: 129,
    name: 'Ubs Brasil Bi S.A',
    concatenated: '129 - Ubs Brasil Bi S.A',
  },
  {
    code: 128,
    name: 'Ms Bank S.A Banco De Câmbio',
    concatenated: '128 - Ms Bank S.A Banco De Câmbio',
  },
  {
    code: 194,
    name: 'Parmetal Dtvm Ltda',
    concatenated: '194 - Parmetal Dtvm Ltda',
  },
  {
    code: 310,
    name: 'Vortx Dtvm Ltda',
    concatenated: '310 - Vortx Dtvm Ltda',
  },
  {
    code: 163,
    name: 'Commerzbank Brasil S.A Banco Múltiplo',
    concatenated: '163 - Commerzbank Brasil S.A Banco Múltiplo',
  },
  {
    code: 280,
    name: 'Avista S.A',
    concatenated: '280 - Avista S.A',
  },
  {
    code: 146,
    name: 'Guitta Cc Ltda',
    concatenated: '146 - Guitta Cc Ltda',
  },
  {
    code: 279,
    name: 'Ccr De Primavera Do Leste',
    concatenated: '279 - Ccr De Primavera Do Leste',
  },
  {
    code: 182,
    name: 'Dacasa Financeira S/A',
    concatenated: '182 - Dacasa Financeira S/A',
  },
  {
    code: 278,
    name: 'Genial Investimentos Cvm S.A',
    concatenated: '278 - Genial Investimentos Cvm S.A',
  },
  {
    code: 271,
    name: 'Ib Cctvm Ltda',
    concatenated: '271 - Ib Cctvm Ltda',
  },
  {
    code: 21,
    name: 'Banco Banestes S.A',
    concatenated: '21 - Banco Banestes S.A',
  },
  {
    code: 246,
    name: 'Banco Abc Brasil S.A',
    concatenated: '246 - Banco Abc Brasil S.A',
  },
  {
    code: 751,
    name: 'Scotiabank Brasil',
    concatenated: '751 - Scotiabank Brasil',
  },
  {
    code: 208,
    name: 'Banco Btg Pactual S.A',
    concatenated: '208 - Banco Btg Pactual S.A',
  },
  {
    code: 746,
    name: 'Banco Modal S.A',
    concatenated: '746 - Banco Modal S.A',
  },
  {
    code: 241,
    name: 'Banco Classico S.A',
    concatenated: '241 - Banco Classico S.A',
  },
  {
    code: 612,
    name: 'Banco Guanabara S.A',
    concatenated: '612 - Banco Guanabara S.A',
  },
  {
    code: 604,
    name: 'Banco Industrial Do Brasil S.A',
    concatenated: '604 - Banco Industrial Do Brasil S.A',
  },
  {
    code: 505,
    name: 'Banco Credit Suisse (Brl) S.A',
    concatenated: '505 - Banco Credit Suisse (Brl) S.A',
  },
  {
    code: 196,
    name: 'Banco Fair Cc S.A',
    concatenated: '196 - Banco Fair Cc S.A',
  },
  {
    code: 300,
    name: 'Banco La Nacion Argentina',
    concatenated: '300 - Banco La Nacion Argentina',
  },
  {
    code: 477,
    name: 'Citibank N.A',
    concatenated: '477 - Citibank N.A',
  },
  {
    code: 266,
    name: 'Banco Cedula S.A',
    concatenated: '266 - Banco Cedula S.A',
  },
  {
    code: 122,
    name: 'Banco Bradesco Berj S.A',
    concatenated: '122 - Banco Bradesco Berj S.A',
  },
  {
    code: 376,
    name: 'Banco J.P. Morgan S.A',
    concatenated: '376 - Banco J.P. Morgan S.A',
  },
  {
    code: 473,
    name: 'Banco Caixa Geral Brasil S.A',
    concatenated: '473 - Banco Caixa Geral Brasil S.A',
  },
  {
    code: 745,
    name: 'Banco Citibank S.A',
    concatenated: '745 - Banco Citibank S.A',
  },
  {
    code: 120,
    name: 'Banco Rodobens S.A',
    concatenated: '120 - Banco Rodobens S.A',
  },
  {
    code: 265,
    name: 'Banco Fator S.A',
    concatenated: '265 - Banco Fator S.A',
  },
  {
    code: 7,
    name: 'Bndes (Banco Nacional Do Desenvolvimento Social)',
    concatenated: '7 - Bndes (Banco Nacional Do Desenvolvimento Social)',
  },
  {
    code: 188,
    name: 'Ativa S.A Investimentos',
    concatenated: '188 - Ativa S.A Investimentos',
  },
  {
    code: 134,
    name: 'Bgc Liquidez Dtvm Ltda',
    concatenated: '134 - Bgc Liquidez Dtvm Ltda',
  },
  {
    code: 641,
    name: 'Banco Alvorada S.A',
    concatenated: '641 - Banco Alvorada S.A',
  },
  {
    code: 29,
    name: 'Banco Itaú Consignado S.A',
    concatenated: '29 - Banco Itaú Consignado S.A',
  },
  {
    code: 243,
    name: 'Banco Máxima S.A',
    concatenated: '243 - Banco Máxima S.A',
  },
  {
    code: 78,
    name: 'Haitong Bi Do Brasil S.A',
    concatenated: '78 - Haitong Bi Do Brasil S.A',
  },
  {
    code: 111,
    name: 'Banco Oliveira Trust Dtvm S.A',
    concatenated: '111 - Banco Oliveira Trust Dtvm S.A',
  },
  {
    code: 17,
    name: 'Bny Mellon Banco S.A',
    concatenated: '17 - Bny Mellon Banco S.A',
  },
  {
    code: 174,
    name: 'Pernambucanas Financ S.A',
    concatenated: '174 - Pernambucanas Financ S.A',
  },
  {
    code: 495,
    name: 'La Provincia Buenos Aires Banco',
    concatenated: '495 - La Provincia Buenos Aires Banco',
  },
  {
    code: 125,
    name: 'Brasil Plural S.A Banco',
    concatenated: '125 - Brasil Plural S.A Banco',
  },
  {
    code: 488,
    name: 'Jpmorgan Chase Bank',
    concatenated: '488 - Jpmorgan Chase Bank',
  },
  {
    code: 65,
    name: 'Banco Andbank S.A',
    concatenated: '65 - Banco Andbank S.A',
  },
  {
    code: 492,
    name: 'Ing Bank N.V',
    concatenated: '492 - Ing Bank N.V',
  },
  {
    code: 250,
    name: 'Banco Bcv',
    concatenated: '250 - Banco Bcv',
  },
  {
    code: 145,
    name: 'Levycam Ccv Ltda',
    concatenated: '145 - Levycam Ccv Ltda',
  },
  {
    code: 494,
    name: 'Banco Rep Oriental Uruguay',
    concatenated: '494 - Banco Rep Oriental Uruguay',
  },
  {
    code: 253,
    name: 'Bexs Cc S.A',
    concatenated: '253 - Bexs Cc S.A',
  },
  {
    code: 269,
    name: 'Hsbc Banco De Investimento',
    concatenated: '269 - Hsbc Banco De Investimento',
  },
  {
    code: 213,
    name: 'Bco Arbi S.A',
    concatenated: '213 - Bco Arbi S.A',
  },
  {
    code: 139,
    name: 'Intesa Sanpaolo Brasil S.A',
    concatenated: '139 - Intesa Sanpaolo Brasil S.A',
  },
  {
    code: 18,
    name: 'Banco Tricury S.A',
    concatenated: '18 - Banco Tricury S.A',
  },
  {
    code: 630,
    name: 'Banco Intercap S.A',
    concatenated: '630 - Banco Intercap S.A',
  },
  {
    code: 224,
    name: 'Banco Fibra S.A',
    concatenated: '224 - Banco Fibra S.A',
  },
  {
    code: 600,
    name: 'Banco Luso Brasileiro S.A',
    concatenated: '600 - Banco Luso Brasileiro S.A',
  },
  {
    code: 623,
    name: 'Banco Pan',
    concatenated: '623 - Banco Pan',
  },
  {
    code: 204,
    name: 'Banco Bradesco Cartoes S.A',
    concatenated: '204 - Banco Bradesco Cartoes S.A',
  },
  {
    code: 479,
    name: 'Banco Itaubank S.A',
    concatenated: '479 - Banco Itaubank S.A',
  },
  {
    code: 456,
    name: 'Banco Mufg Brasil S.A',
    concatenated: '456 - Banco Mufg Brasil S.A',
  },
  {
    code: 464,
    name: 'Banco Sumitomo Mitsui Brasil S.A',
    concatenated: '464 - Banco Sumitomo Mitsui Brasil S.A',
  },
  {
    code: 613,
    name: 'Omni Banco S.A',
    concatenated: '613 - Omni Banco S.A',
  },
  {
    code: 652,
    name: 'Itaú Unibanco Holding Bm S.A',
    concatenated: '652 - Itaú Unibanco Holding Bm S.A',
  },
  {
    code: 653,
    name: 'Banco Indusval S.A',
    concatenated: '653 - Banco Indusval S.A',
  },
  {
    code: 69,
    name: 'Banco Crefisa S.A',
    concatenated: '69 - Banco Crefisa S.A',
  },
  {
    code: 370,
    name: 'Banco Mizuho S.A',
    concatenated: '370 - Banco Mizuho S.A',
  },
  {
    code: 249,
    name: 'Banco Investcred Unibanco S.A',
    concatenated: '249 - Banco Investcred Unibanco S.A',
  },
  {
    code: 318,
    name: 'Banco Bmg S.A',
    concatenated: '318 - Banco Bmg S.A',
  },
  {
    code: 626,
    name: 'Banco Ficsa S.A',
    concatenated: '626 - Banco Ficsa S.A',
  },
  {
    code: 270,
    name: 'Sagitur Cc Ltda',
    concatenated: '270 - Sagitur Cc Ltda',
  },
  {
    code: 366,
    name: 'Banco Societe Generale Brasil',
    concatenated: '366 - Banco Societe Generale Brasil',
  },
  {
    code: 113,
    name: 'Magliano S.A',
    concatenated: '113 - Magliano S.A',
  },
  {
    code: 131,
    name: 'Tullett Prebon Brasil Cvc Ltda',
    concatenated: '131 - Tullett Prebon Brasil Cvc Ltda',
  },
  {
    code: 11,
    name: 'C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)',
    concatenated: '11 - C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)',
  },
  {
    code: 611,
    name: 'Banco Paulista',
    concatenated: '611 - Banco Paulista',
  },
  {
    code: 755,
    name: 'Bofa Merrill Lynch Bm S.A',
    concatenated: '755 - Bofa Merrill Lynch Bm S.A',
  },
  {
    code: 89,
    name: 'Ccr Reg Mogiana',
    concatenated: '89 - Ccr Reg Mogiana',
  },
  {
    code: 643,
    name: 'Banco Pine S.A',
    concatenated: '643 - Banco Pine S.A',
  },
  {
    code: 140,
    name: 'Easynvest – Título Cv S.A',
    concatenated: '140 - Easynvest – Título Cv S.A',
  },
  {
    code: 707,
    name: 'Banco Daycoval S.A',
    concatenated: '707 - Banco Daycoval S.A',
  },
  {
    code: 288,
    name: 'Carol Dtvm Ltda',
    concatenated: '288 - Carol Dtvm Ltda',
  },
  {
    code: 101,
    name: 'Renascenca Dtvm Ltda',
    concatenated: '101 - Renascenca Dtvm Ltda',
  },
  {
    code: 487,
    name: 'Deutsche Bank S.A Banco Alemão',
    concatenated: '487 - Deutsche Bank S.A Banco Alemão',
  },
  {
    code: 233,
    name: 'Banco Cifra',
    concatenated: '233 - Banco Cifra',
  },
  {
    code: 177,
    name: 'Guide',
    concatenated: '177 - Guide',
  },
  {
    code: 633,
    name: 'Banco Rendimento S.A',
    concatenated: '633 - Banco Rendimento S.A',
  },
  {
    code: 218,
    name: 'Banco Bs2 S.A',
    concatenated: '218 - Banco Bs2 S.A',
  },
  {
    code: 292,
    name: 'Bs2 Distribuidora De Títulos E Investimentos',
    concatenated: '292 - Bs2 Distribuidora De Títulos E Investimentos',
  },
  {
    code: 169,
    name: 'Banco Olé Bonsucesso Consignado S.A',
    concatenated: '169 - Banco Olé Bonsucesso Consignado S.A',
  },
  {
    code: 293,
    name: 'Lastro Rdv Dtvm Ltda',
    concatenated: '293 - Lastro Rdv Dtvm Ltda',
  },
  {
    code: 285,
    name: 'Frente Cc Ltda',
    concatenated: '285 - Frente Cc Ltda',
  },
  {
    code: 80,
    name: 'B&T Cc Ltda',
    concatenated: '80 - B&T Cc Ltda',
  },
  {
    code: 753,
    name: 'Novo Banco Continental S.A Bm',
    concatenated: '753 - Novo Banco Continental S.A Bm',
  },
  {
    code: 222,
    name: 'Banco Crédit Agricole Br S.A',
    concatenated: '222 - Banco Crédit Agricole Br S.A',
  },
  {
    code: 754,
    name: 'Banco Sistema',
    concatenated: '754 - Banco Sistema',
  },
  {
    code: 98,
    name: 'Credialiança Ccr',
    concatenated: '98 - Credialiança Ccr',
  },
  {
    code: 610,
    name: 'Banco Vr S.A',
    concatenated: '610 - Banco Vr S.A',
  },
  {
    code: 712,
    name: 'Banco Ourinvest S.A',
    concatenated: '712 - Banco Ourinvest S.A',
  },
  {
    code: 10,
    name: 'Credicoamo',
    concatenated: '10 - Credicoamo',
  },
  {
    code: 283,
    name: 'Rb Capital Investimentos Dtvm Ltda',
    concatenated: '283 - Rb Capital Investimentos Dtvm Ltda',
  },
  {
    code: 217,
    name: 'Banco John Deere S.A',
    concatenated: '217 - Banco John Deere S.A',
  },
  {
    code: 117,
    name: 'Advanced Cc Ltda',
    concatenated: '117 - Advanced Cc Ltda',
  },
  {
    code: 336,
    name: 'Banco C6 S.A – C6 Bank',
    concatenated: '336 - Banco C6 S.A – C6 Bank',
  },
  {
    code: 654,
    name: 'Banco A.J. Renner S.A',
    concatenated: '654 - Banco A.J. Renner S.A',
  },
];
