export const UserRole = {
  Admin: 0,
  Analyst: 1,
  Financial: 2,
  Marketing: 3,
  Attendance: 4,
  Partner: 5,
  PartnerAdmin: 6,
  SuperAdmin: 420,
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';
 
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: "AIzaSyDZEPrTDvGP3kN2421CBl4eQHd-tlphC_0",
  authDomain: "koikorn-27d00.firebaseapp.com",
  projectId: "koikorn-27d00",
  storageBucket: "koikorn-27d00.appspot.com",
  messagingSenderId: "471366720272",
  appId: "1:471366720272:web:068feb9afbeb1229bb6395",
  measurementId: "G-7GVRZG1VCB"
};

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Financial,
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.orangecarrot';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
