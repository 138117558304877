import {
  SET_OPEN_LEADS,
  SET_CONSULTED_LEADS,
  SET_EXPORTED_LEADS,
  SET_CNAB_LEADS,
  SET_SEND_OPERATIONS,
  SET_SENT_OPERATIONS,
  SET_HISTORIC_OPERATIONS,
  SET_OPEN_CANCELLATION_REQUESTS,
  SET_FINALIZED_CANCELLATION_REQUESTS,
  SET_UNDER_ANALYSIS_REQUESTS,
  SET_APPROVED_REQUESTS,
  SET_DISAPPROVED_REQUESTS,
  SET_CHARGED_REQUESTS,
  SET_LAST_CANCELLATION_REQUESTS,
  SET_RESEND_REQUESTS,
} from '../actions';

const INIT_STATE = {
  openLeads: [],
  chargedLeads: [],
  consultedLeads: [],
  exportedLeads: [],
  cnabLeads: [],
  sendOperations: [],
  sentOperations: [],
  historicOperations: [],
  openCancellationRequests: [],
  underAnalysisRequests: [],
  approvedRequests: [],
  disapprovedRequests: [],
  finalizedCancellationRequests: [],
  lastCancellationRequests: [],
  resendRequests: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_OPEN_LEADS:
      return { ...state, openLeads: action.payload };

    case SET_CHARGED_REQUESTS:
      return { ...state, chargedLeads: action.payload };

    case SET_CONSULTED_LEADS:
      return { ...state, consultedLeads: action.payload };

    case SET_EXPORTED_LEADS:
      return { ...state, exportedLeads: action.payload };

    case SET_CNAB_LEADS:
      return { ...state, cnabLeads: action.payload };

    case SET_SEND_OPERATIONS:
      return { ...state, sendOperations: action.payload };

    case SET_SENT_OPERATIONS:
      return { ...state, sentOperations: action.payload };
      
    case SET_HISTORIC_OPERATIONS:
      return { ...state, historicOperations: action.payload };

    case SET_OPEN_CANCELLATION_REQUESTS:
      return { ...state, openCancellationRequests: action.payload };

    case SET_UNDER_ANALYSIS_REQUESTS:
      return { ...state, underAnalysisRequests: action.payload };

    case SET_APPROVED_REQUESTS:
      return { ...state, approvedRequests: action.payload };

    case SET_DISAPPROVED_REQUESTS:
      return { ...state, disapprovedRequests: action.payload };

    case SET_FINALIZED_CANCELLATION_REQUESTS:
      return { ...state, finalizedCancellationRequests: action.payload };

    case SET_LAST_CANCELLATION_REQUESTS:
      return { ...state, lastCancellationRequests: action.payload };

    case SET_RESEND_REQUESTS:
      return { ...state, resendRequests: action.payload }

    default:
      return { ...state };
  }
};
